window.__tnt || (window.__tnt = {});

(function(document, __tnt) {
    
    var me = __tnt.slider || (__tnt.slider = {}),
        sliderApp = {};

    // Click event delegation for opening modals for all sliders
    document.addEventListener('click', function(e) {
        // Open modal
        var expandEl = e.target.closest('[data-toggle][data-instance][data-photo-target]');
        if (expandEl) {
            var modalId = expandEl.dataset.instance.replace('#gallery-items-', '').replaceAll('-', '_'),
                modalCarousel = document.querySelector('div[data-id="' + modalId + '"]');
            if (modalCarousel) {
                utilities.displayModal(modalCarousel, e, expandEl);
            }
        }
    });

    // Template.content polyfill
    var templateContent = function(template) {
        if ('content' in document.createElement('template')) {
            return template.content;
        } else {
            var fragment = document.createDocumentFragment();
            var children = template.childNodes;
            for (i = 0; i < children.length; i++) {
                fragment.appendChild(children[i].cloneNode(true));
            }
            return fragment;
        }
    };

    me.sliderApp = {
        init: function() {
            // collect all slideshow templates
            sliderApp.slideshows = document.querySelectorAll('.tnt-slider-template');
            
            // initialize slider ready event
            tntSliderReady = new CustomEvent('tntSliderReady');
            
            // construct each slider instance
            for (var i = 0; i < sliderApp.slideshows.length; ++i) {
                var slideshowEl = sliderApp.slideshows[i];
                
                me.sliderApp.constructSlider(slideshowEl);
                
                // Add slider ready class and dispatch ready event
                slideshowEl.classList.add('tnt-slider-ready');
                slideshowEl.dispatchEvent(tntSliderReady);
            }
        },
        constructSlider: function(el) {
            var container = document.createElement('div'),
                outerStage = document.createElement('div'),
                innerStage = document.createElement('div'),
                templateFrag = templateContent(el).cloneNode(true),
                slidesLength = templateFrag.children.length,
                endIndex = (el.dataset.fetchEndpoint || el.dataset.columns || el.dataset.disableSlideLimit == 'true' || slidesLength < 30) ? slidesLength : 30,
                clone,
                cloneParent,
                i,
                j,
                slide;
            container.classList.add('tnt-slider');
            outerStage.classList.add('tnt-slider-outer');
            innerStage.classList.add('tnt-slider-inner');
            container.id = 'tnt-slider-' + el.dataset.id;
            container.dataset.totalChildren = templateFrag.children.length;
            
            for (i = 0; i < el.attributes.length; i++) {
                if (el.attributes[i].name != 'class') container.setAttribute(el.attributes[i].name, el.attributes[i].value);
            }
            
            if (el.dataset.autoHeight == 'true') outerStage.classList.add('tnt-slider-height');
            
            // Create slider items
            for (i = 0; i < endIndex; i++) {
                clone = templateFrag.children[i];
                slide = document.createElement('div');
                
                slide.classList.add('tnt-slider-item');
                
                // identify final content slide
                if (i == endIndex - 1) {
                    slide.classList.add('last');
                }
                
                // Set position value
                slide.dataset.item = i + 1;
                
                // Add asset to slide
                slide.appendChild(document.importNode(clone, true));
                
                // Add slide to set
                innerStage.appendChild(slide);
            }

            // Columns
            if (el.dataset.columns) {
                var slides = document.importNode(templateContent(el).cloneNode(true), true),
                    visibleSlides = utilities.visibleSlides(el);
                
                if (visibleSlides > slides.children.length) visibleSlides = slides.children.length;
                
                for (j = 0; j < slides.children.length; j++) {
                    if (j < visibleSlides) {
                        var clones = slides.children[j];
                        cloneParent = document.createElement('div');
                        cloneParent.classList.add('tnt-slider-item');
                        cloneParent.classList.add('clone');
                        cloneParent.dataset.item = slides.children.length + j + 1;
                        if (!el.dataset.blankclones) {
                            cloneParent.appendChild(document.importNode(clones, true));
                        }
                        innerStage.appendChild(cloneParent);
                    }
                }
            }
            
            // Merge pieces and append to slider container in DOM
            outerStage.appendChild(innerStage);
            container.appendChild(outerStage);
            el.parentElement.appendChild(container);
            
            /* Outbrain shim for IE11 - Remove template children after slides are created */
            while (el.firstChild) {
                el.removeChild(el.firstChild);
            }
            
            utilities.setTranslate(container, 1);
            utilities.loaded(container);
            
            this.observeSlider(container);
            this.setStage(container);
            this.createNavigation(container);
            this.createListener(container);
            
            var activeContainer = el.parentElement.querySelector('.tnt-slider'),
                allSlides = activeContainer.querySelectorAll('.tnt-slider-item'),
                activeCount = utilities.visibleSlides(activeContainer);
            
            activeContainer.dataset.slideTotal = allSlides.length;
            if (activeContainer.dataset.fetchEndpoint) activeContainer.dataset.fetchCount = activeContainer.dataset.assetCount;
            
            // Class initial active slides
            for (var i = 0; i < allSlides.length; i++) {
                if (i >= activeCount) break;
                allSlides[i].classList.add('active');
            }
            
            // Add slide count
            if (el.dataset.showCount == 'true' && el.dataset.modal == 'true') {
                var photoCountWrapper = document.createElement('span'),
                    photoCount = document.createElement('span'),
                    totalCount = document.createElement('span'),
                    countValue = el.dataset.assetCount;
                
                // Build elements to contain initial count
                photoCountWrapper.classList.add('tnt-slider-counter', 'photo-count');
                photoCount.classList.add('index');
                photoCount.innerHTML = 1;
                photoCountWrapper.prepend(photoCount);
                totalCount.classList.add('total');
                photoCountWrapper.append(totalCount);
                totalCount.innerHTML = " of " + countValue;
                // Add to page
                el.nextElementSibling.prepend(photoCountWrapper);
            }
            
            // Build engagement slide if enabled
            if (document.querySelector('.tnt-slider-inner').lastElementChild.firstElementChild.classList.contains('engagement-slide')) {
                var id = el.dataset.id.replace('_photo_modal', '').replaceAll('_', '-');
                var s = document.createElement('script');
                var engagementCode;
                    
                // define engagement code
                engagementCode = "function engagementCode(){__tnt.engagement.assets['" + id + "'].populate(document.getElementById('engagement-more').getAttribute('data-next-url'));} engagementCode();"
                
                document.querySelectorAll('.photo-container.engagement-stage').forEach(function(elem) {
                    elem.classList.remove('engagement-stage');
                    elem.classList.add('engagement-height');
                });
                
                // Remove 'engagement-height' class from elements
                document.querySelectorAll('.photo-container.engagement-height').forEach(function(elem) {
                    elem.classList.remove('engagement-height');
                });
            
                // Use setTimeout to execute code after a delay
                setTimeout(function() {
                    // Remove 'engagment-mask' class
                    document.querySelectorAll('.engagement-slide .photo-container').forEach(function(elem) {
                        elem.classList.remove('engagment-mask');
                    });
            
                    // Remove '.loading-slide' elements
                    document.querySelectorAll('.engagement-slide .photo-container .loading-slide').forEach(function(elem) {
                        elem.parentNode.removeChild(elem);
                    });
            
                    // Another setTimeout to execute code after a delay
                    setTimeout(function() {
                        var outerHeight = document.querySelector('.engagement-slide').offsetHeight;
                        document.querySelector('.tnt-slider-outer').style.height = outerHeight + 'px';
                    }, 500);
            
                }, 500);
                
                
                // remove empty engagement slide
                setTimeout(function() {
                    var engagementContainer = document.querySelector('.engagement-container');
                    if (engagementContainer && engagementContainer.getAttribute('data-engagement-total') == 0) {
                        el.querySelector('.tnt-slider-inner').lastElementChild.remove();
                    }
                }, 2000);
                
                
                // add engagement content after engagement macro is loaded
                s.text = engagementCode;
                document.body.appendChild( s );
            }
        },
        observeSlider: function(el) {
            if (el.dataset.autoSlide == 'true') {
                el.autoslide = {
                    'allowAutoSlide': null,
                    'timer': null
                };
                if ('IntersectionObserver' in window &&
                    'IntersectionObserverEntry' in window &&
                    'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
                    
                    var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                        bLeaving = false,
                        observer = new IntersectionObserver(function(entries) {
                            entries.forEach(function(entry) {
                                if (entry.isIntersecting) {
                                    bLeaving = true;
                                    el.autoslide.allowAutoSlide = true;
                                    utilities.autoSlide(el);
                                } else if (bLeaving) {
                                    bLeaving = false;
                                    el.autoslide.allowAutoSlide = false;
                                    utilities.autoSlide(el);
                                }
                            });
                        });
                    
                    observer.observe(slideshowContainer);
                }
            }
        },
        setStage: function(el) {
            utilities.stageSize(el);
        },
        createNavigation: function(el) {
            var slides = el.querySelectorAll('.tnt-slider-item'),
                cloneSlides = el.querySelectorAll('.tnt-slider-item.clone'),
                realSlides = 0,
                visibleSlides = utilities.visibleSlides(el),
                showNav = true;
            
            if (slides && cloneSlides) {
                realSlides = slides.length - cloneSlides.length;
                // Hide navigation when nav is not needed
                if (realSlides <= visibleSlides) {
                    showNav = false;
                }
            }
            
            if (el.dataset.arrows == 'true' && showNav) {
                
                /** Navigation container */
                var navContainer = document.createElement('div');
                navContainer.classList.add('tnt-slider-controls');
                navContainer.classList.add('tnt-slider-controls-top');
                if (el.dataset.sliderType == 'pagination') navContainer.classList.add('hidden-print');
                
                /** Navigation btns */
                var nextBtn = utilities.arrowElement(el, {
                        'text': 'Next',
                        'btn': 'tnt-slider-next',
                        'icon': 'tnt-chevron-right'
                    }),
                    prevBtn = utilities.arrowElement(el, {
                        'text': 'Previous',
                        'btn': 'tnt-slider-previous',
                        'icon': 'tnt-chevron-left'
                    });
                
                /** Merge btns with container */
                navContainer.appendChild(prevBtn);
                navContainer.appendChild(nextBtn);
                
                if (el.dataset.sliderType == 'pagination') {
                    var clone = navContainer.cloneNode(true);
                    clone.classList.add('tnt-slider-controls-bottom');
                    clone.classList.remove('tnt-slider-controls-top');
                    el.appendChild(clone);
                }
                
                el.prepend(navContainer);
                
                if (el.dataset.sliderType == 'pagination') {
                    var counterContainer = document.createElement('div'),
                        sTotal = el.dataset.assetCount,
                        topNavigation = el.querySelector('.tnt-slider-controls-top'),
                        bottomNavigation = el.querySelector('.tnt-slider-controls-bottom');
                    counterContainer.classList.add('tnt-slider-counter');
                    counterContainer.innerHTML = '<p>Page <span class="index">1</span> of <span class="total">' + sTotal + '</span>';
                    
                    var cloneContainer = counterContainer.cloneNode(true);
                    
                    topNavigation.prepend(cloneContainer);
                    bottomNavigation.prepend(counterContainer);
                }
            }
        },
        /** Previous slide */
        previousSlide: function(el) {
            var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                curPos = parseInt(utilities.currentPosition(el)),
                offset = 1,
                nextPos = curPos - offset,
                visibleSlides = parseInt(utilities.visibleSlides(el));
            
            if (!el.dataset.singleadvance) {
                if (curPos != 1 && curPos <= visibleSlides) {
                    // If first set of items return to 1
                    nextPos = 1;
                } else {
                    offset = visibleSlides;
                    nextPos = curPos - offset;
                }
            }
            
            /* Prevent loop to end if not set */
            if (el.dataset.loop == 'false' && nextPos == 0) {
                return;
            }
            
            /* Loop to last */
            if (nextPos < 1) {
                if (el.dataset.columns) {
                    nextPos = slideshowContainer.children.length - visibleSlides;
                    nextPos = (nextPos < 1) ? 1 : nextPos;
                } else {
                    nextPos = slideshowContainer.children.length;
                }
            }
            
            me.sliderApp.slideTo(el, nextPos, 'prev');
            
            // Update slide count
            utilities.slideCounter(el);
        },
        /** Next slide */
        nextSlide: function(el) {
            var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                curPos = parseInt(utilities.currentPosition(el)),
                offset = 1,
                nextPos = curPos + offset;
            
            if (!el.dataset.singleadvance) {
                offset = parseInt(utilities.visibleSlides(el));
                nextPos = curPos + offset;
            }
            
            if (nextPos > slideshowContainer.children.length) {
                nextPos = 1
            }
            
            me.sliderApp.slideTo(el, nextPos, 'next');
            
            // Update slide count
            utilities.slideCounter(el);
        },
        slideTo: function(el, nextPos, dir) {
            // Throttle if fetching
            if (el.dataset.fetching == 'true' && nextPos >= el.dataset.slideTotal - 1) return;
            
            var activeSlide = el.querySelector('.active'),
                slideshowContainer = el.querySelector('.tnt-slider-inner'),
                curPos = parseInt(utilities.currentPosition(el)),
                offset = 1,
                totalSlides = parseInt(el.dataset.slideTotal),
                totalChildren = parseInt(el.dataset.totalChildren),
                clones = utilities.countSliderClones(slideshowContainer),
                contentTotal = totalSlides - clones,
                trackEl = el.querySelector('[data-item="' + nextPos + '"]').querySelector('[data-track-object]'),
                visibleSlides = parseInt(utilities.visibleSlides(el));
            
            // Reach out to endpoint for more
            if (el.dataset.fetchEndpoint) {
                var fetchOffset = 10;
                if (el.dataset.fetchCount <= fetchOffset) fetchOffset = 2
                if (nextPos >= contentTotal - fetchOffset) utilities.fetchSlides(el, nextPos);

            // Pull more elements from the template
            } else if (!el.dataset.columns && totalSlides < totalChildren && nextPos >= contentTotal - 5) {
                utilities.fetchTemplateSlides(el, nextPos);
            }
            
            // Prevent transition when no other slides are available
            if (contentTotal <= visibleSlides) {
                return;
            }
            
            // Set stage offset for advance
            if (!el.dataset.singleadvance) {
                offset = visibleSlides;
            }
            
            var nextLastPos = visibleSlides == 1 ? nextPos : nextPos + (offset - 1);
            
            /* Loop to first */
            if (nextPos > contentTotal) {
                if (el.dataset.loop == 'false') {
                    return;
                } else {
                    nextPos = 1;
                    nextLastPos = visibleSlides == 1 ? nextPos : nextPos + (offset - 1);
                }
            }
            
            /* Loop to last */
            if (nextPos < 1) {
                if (el.dataset.loop == 'false') {
                    return;
                } else {
                    nextPos = contentTotal;
                }
            }
            
            // Set active classes
            if (el.dataset.columns) {
                for (var i = 0; i < slideshowContainer.children.length; i++) {
                    var slide = slideshowContainer.children[i],
                        sNum = slide.dataset.item;
                    
                    if (sNum >= nextPos && sNum <= nextLastPos) {
                        slide.classList.add('active');
                    } else {
                        slide.classList.remove('active');
                    }
                }
            } else {
                slideshowContainer.children[curPos - 1].classList.remove('active');
                slideshowContainer.children[nextPos - 1].classList.add('active');
            }
            
            // Track virtual pageview
            if (el.dataset.trackVirtual && trackEl) {
                __tnt.trackEvent(JSON.parse(trackEl.dataset.trackObject));
            }
            
            utilities.setTranslate(el, nextPos);
            if (!el.dataset.columns) utilities.beforeSlide(el, nextPos);
            
            setTimeout(function() {
                utilities.afterSlide(el, dir);
            }, 100);
        },
        resize: function(el, activeSlide) {
            utilities.stageSize(el);
            utilities.setTranslate(el, activeSlide);
            utilities.autoHeight(el, activeSlide);
            utilities.visibleSlides(el);
            utilities.moveActiveClass(el);
        },
        createListener: function(el) {
            var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                btnsNext = el.querySelectorAll('.tnt-slider-next'),
                btnsPrev = el.querySelectorAll('.tnt-slider-previous'),
                i;
            
            for (i = 0; i < btnsPrev.length; ++i) {
                btnsPrev[i].addEventListener('click', function() {
                    me.sliderApp.previousSlide(el);
                });
            }
            
            for (i = 0; i < btnsNext.length; ++i) {
                btnsNext[i].addEventListener('click', function() {
                    me.sliderApp.nextSlide(el);
                });
            }
            
            if (el.dataset.autoSlide == 'true') {
                slideshowContainer.addEventListener('mouseover', function() {
                    el.autoslide.allowAutoSlide = false;
                    utilities.autoSlide(el);
                });
                
                slideshowContainer.addEventListener('mouseout', function() {
                    el.autoslide.allowAutoSlide = true;
                    utilities.autoSlide(el);
                });
            }
            
            if (el.dataset.swipe == 'true') {
                slideshowContainer.addEventListener('click', function(e) {
                    utilities.freezeClick(el, e);
                });
                
                if ('PointerEvent' in window) {
                    slideshowContainer.addEventListener('pointerdown', function(e) {
                        utilities.moveStart(el, e);
                    });
                    
                    slideshowContainer.addEventListener('pointermove', function(e) {
                        utilities.moveSlider(el, e);
                    });
                    
                    slideshowContainer.addEventListener('pointerup', function(e) {
                        utilities.moveEnd(el, e);
                    });
                    
                    slideshowContainer.addEventListener('pointerleave', function(e) {
                        utilities.moveEnd(el, e);
                    });
                    
                    slideshowContainer.addEventListener('pointercancel', function(e) {
                        utilities.moveEnd(el, e);
                    });
                    
                } else if (window.navigator && 'msPointerEnabled' in window.navigator) {
                    slideshowContainer.addEventListener('MSPointerDown', function(e) {
                        utilities.moveStart(el, e);
                    });
                    
                    slideshowContainer.addEventListener('MSPointerMove', function(e) {
                        utilities.moveSlider(el, e);
                    });
                    
                    slideshowContainer.addEventListener('MSPointerUp', function(e) {
                        utilities.moveEnd(el, e);
                    });
                    
                } else {
                
                    if (window.navigator.maxTouchPoints && window.navigator.maxTouchPoints > 0) {
                        slideshowContainer.addEventListener('touchstart', function(e) {
                            utilities.moveStart(el, e);
                        }, { passive: true });
                        
                        slideshowContainer.addEventListener('touchmove', function(e) {
                            utilities.moveSlider(el, e);
                        }, { passive: true });
                        
                        slideshowContainer.addEventListener('touchend', function(e) {
                            utilities.moveEnd(el, e);
                        }, { passive: true });

                        slideshowContainer.addEventListener('touchcancel', function(e) {
                            utilities.moveEnd(el, e);
                        }, { passive: true });
                    } else {
                
                        slideshowContainer.addEventListener('mousedown', function(e) {
                            utilities.moveStart(el, e);
                        });
        
                        slideshowContainer.addEventListener('mousemove', function(e) {
                            utilities.moveSlider(el, e);
                        });
                        
                        slideshowContainer.addEventListener('mouseup', function(e) {
                            utilities.moveEnd(el, e);
                        });
                        
                        slideshowContainer.addEventListener('mouseleave', function(e) {
                            utilities.moveEnd(el, e);
                        });
                    }
                }
            }
            
            var resizeTimer;
            
            window.addEventListener('resize', function() {
                // Return if slider is not visible
                if (el.dataset.modal == 'false' && el.closest('.modal')) {
                    if (el.closest('.modal').classList.contains('in') == false) return;
                }
                
                el.querySelector('.tnt-slider-inner').classList.add('tnt-slider-resizing');
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function() {
                    var activeSlide = el.querySelector('.active').dataset.item;
                    me.sliderApp.resize(el, activeSlide);
                    el.querySelector('.tnt-slider-inner').classList.remove('tnt-slider-resizing');
                }, 250);
            });
        }
    };
    var utilities = {
        fetchTemplateSlides: function(el, activeSlide) {
            // Do nothing ... if currently fetching
            if (el.dataset.fetching == 'true') return;

            // Slider is fetching new slides
            el.dataset.fetching = 'true';

            var templateEl = el.previousElementSibling,
                templateFrag = templateContent(templateEl).cloneNode(true),
                innerStage = el.querySelector('.tnt-slider-inner'),
                slidePos = parseInt(el.querySelector('.tnt-slider-item.last').dataset.item),
                startIndex = slidePos,
                endIndex = (startIndex + 10 <= templateFrag.children.length) ? startIndex + 10 : templateFrag.children.length,
                companionID = el.dataset.companion;

            // Get companion
            if (companionID) {
                var companion = document.getElementById(companionID),
                    compTemplateEl = companion.previousElementSibling,
                    compTemplateFrag = templateContent(compTemplateEl).cloneNode(true),
                    compInnerStage = companion.querySelector('.tnt-slider-inner');
            }

            // Remove current last class
            el.querySelector('.tnt-slider-item.last').classList.remove('last');
            if (companion) companion.querySelector('.tnt-slider-item.last').classList.remove('last');

            for (var i = startIndex; i < endIndex; i++) {
                var clone = templateFrag.children[i],
                    slide = document.createElement('div');

                slide.classList.add('tnt-slider-item');
                slide.dataset.item = i + 1;
                slide.appendChild(document.importNode(clone, true));
                innerStage.appendChild(slide);

                if (companion) {
                    var compClone = compTemplateFrag.children[i],
                        compSlide = document.createElement('div');

                    compSlide.classList.add('tnt-slider-item');
                    compSlide.dataset.item = i + 1;
                    compSlide.appendChild(document.importNode(compClone, true));
                    compInnerStage.appendChild(compSlide);
                }

                slidePos++;
            }

            // Class last slide and update asset and slide counts
            el.querySelector('.tnt-slider-inner').lastElementChild.classList.add('last');
            el.dataset.slideTotal = slidePos;
            utilities.stageSize(el);

            if (companion) {
                companion.querySelector('.tnt-slider-inner').lastElementChild.classList.add('last');
                companion.dataset.slideTotal = slidePos;
                utilities.stageSize(companion);
            }

            // Slider is finished updating
            el.dataset.fetching = 'false';
        },
        fetchSlides: function(el, activeSlide) {
            // Do nothing w/o endpoint or if currently fetching
            if (!el.dataset.fetchEndpoint || el.dataset.fetching == 'true') return;
            
            // Slider is fetching new slides
            el.dataset.fetching = 'true';
            
            var endpointUrl = new URL(el.dataset.fetchEndpoint),
                endpoint = __tnt.scrubUrl(endpointUrl.pathname + endpointUrl.search),
                ad_insert = parseInt(el.dataset.adInsert),
                ad_offset = parseInt(el.dataset.adOffset),
                ad_template = el.parentElement.querySelector('.ad-slide-template'),
                slidePos = el.querySelector('.tnt-slider-item.last').dataset.item,
                totalAssets = parseInt(el.dataset.assetCount),
                companionID = el.dataset.companion;
            
            if (ad_offset > 0) ad_insert = ad_offset;
            
            // Build ad slide
            if (ad_insert) {
                var ad_clone = ad_template.content.cloneNode(true),
                    ad_slide = document.createElement('div');
                
                ad_slide.classList.add('tnt-slider-item');
                ad_slide.appendChild(ad_clone);
            }
            
            // Get companion
            if (companionID) var companion = document.getElementById(companionID);
            
            // Fetch assets
            fetch(endpoint)
                .then(function(response) {
                    if (response.status !== 200) {
                        __tnt.log("Error. Status Code: " + response.status);
                        return;
                    }
                    // Examine response
                    response.json()
                        .then(function(data) {
                            // Remove current last class
                            el.querySelector('.tnt-slider-item.last').classList.remove('last');
                            if (companion) companion.querySelector('.tnt-slider-item.last').classList.remove('last');
                            
                            // Run through returned assets
                            data.assets.forEach(function(asset, i) {
                                // Increment total asset count number for given asset
                                totalAssets++;
                                
                                // Increment slide count (includes ads)
                                slidePos++
                                
                                // Countdown to ad
                                ad_insert--;
                                
                                // Create initial nodes
                                var masterNode = document.createElement('div'),
                                    inpageSlide = document.createElement('div');
                                
                                // Create usable content node
                                masterNode.innerHTML = asset.content;
                                
                                // Add classes and data to inpage node
                                inpageSlide.classList.add('tnt-slider-item');
                                inpageSlide.dataset.item = slidePos;
                                
                                // Create modal slide from updated inpageSlide
                                var modalSlide = inpageSlide.cloneNode(true)
                                
                                // Insert content into modalSlide
                                var modalContent = masterNode.querySelector('[data-display="modal"]');
                                
                                // Append content to modal slide
                                modalSlide.appendChild( modalContent );
                                
                                // Set slide number
                                modalSlide.querySelector('.item-container').dataset.count = totalAssets;
                                
                                // Get inpage slide content
                                var inpageContent = masterNode.querySelector('[data-display="inpage"]');
                                
                                // Append content to inpage slide
                                inpageSlide.appendChild( inpageContent );
                                
                                // Set slide number
                                inpageSlide.querySelector('.item-container').dataset.count = totalAssets;
                                
                                // Set page number on inpage slide
                                if (el.dataset.sliderType == 'pagination' || companion.dataset.sliderType == 'pagination') {
                                    inpageSlide.querySelector('.paging-position .tnt-layers-text').innerHTML = totalAssets;
                                }
                                
                                // Update inpage slider info
                                var target = inpageSlide.querySelector('[data-target]');
                                
                                // Update inpage slide to open modal location
                                if (target) {
                                    try {
                                        target.dataset.target = el.dataset.targetId;
                                        target.dataset.instance = el.dataset.instanceId;
                                    } catch (e) { console.log(e) }
                                }
                                
                                // Insert content slide
                                if (el.dataset.inModal == 'true') {
                                    el.querySelector('.tnt-slider-inner').appendChild( modalSlide );
                                    companion.querySelector('.tnt-slider-inner').appendChild( inpageSlide );
                                } else {
                                    el.querySelector('.tnt-slider-inner').appendChild( inpageSlide );
                                    
                                    if (companion) {
                                        companion.querySelector('.tnt-slider-inner').appendChild( modalSlide );
                                    }
                                }
                                
                                // Inset ad slide
                                if (ad_insert <= 0) {
                                    // Update slidePos to include ad
                                    slidePos ++;
                                    
                                    // Set position value
                                    ad_slide.dataset.item = slidePos;
                                    
                                    // Append ad slide
                                    el.querySelector('.tnt-slider-inner').appendChild( ad_slide.cloneNode(true) );
                                    if (companion) companion.querySelector('.tnt-slider-inner').appendChild( ad_slide.cloneNode(true) );
                                    
                                    // Reset ad countdown
                                    ad_insert = el.dataset.adInsert;
                                }
                                
                                // Update ad insert
                                el.dataset.adOffset = ad_insert;
                            });
                            
                            // Class last slide and update asset and slide counts
                            el.querySelector('.tnt-slider-inner').lastElementChild.classList.add('last');
                            el.dataset.assetCount = totalAssets;
                            el.dataset.slideTotal = slidePos;
                            
                            if (companion) {
                                companion.querySelector('.tnt-slider-inner').lastElementChild.classList.add('last');
                                companion.dataset.assetCount = totalAssets;
                                companion.dataset.slideTotal = slidePos;
                            }
                            
                            // Update endpoint for next run
                            if (data.next_url) {
                                el.dataset.fetchEndpoint = data.next_url;
                                if (companion) companion.dataset.fetchEndpoint = data.next_url;
                            } else {
                                delete el.dataset.fetchEndpoint;
                                if (companion) delete companion.dataset.fetchEndpoint;
                            }
                            
                            // Update slider sizing for new set
                            me.sliderApp.resize(el, activeSlide);
                            
                            // Slider is finished updating
                            el.dataset.fetching = 'false';
                        });
                })
                .catch(function(err) {
                    __tnt.log("Fetch Error: ", err);
                    el.dataset.fetching = 'false';
                });
        },
        setTriggers: function() {
            var modalTriggers = document.querySelectorAll('[data-toggle][data-instance][data-photo-target]');
            try {
                modalTriggers.forEach(function(el) {
                    if (el.dataset.listener == 'true') return;
                    el.dataset.listener = 'true';
                    el.addEventListener('click', function(e) {
                        // Use clicked item data to get appropriate slider
                        var modalId = el.dataset.instance.replace('#gallery-items-', '').replaceAll('-', '_'),
                            modalCarousel = document.querySelector('div[data-id="' + modalId + '"]');
                        
                        // Call to display carousel at selected clicked item
                        utilities.displayModal(modalCarousel, e, el);
                        // Set listener for modal close
                        utilities.modalClose(modalCarousel);
                    });
                });
            } catch (e) { console.log(e) }
        },
        freezeClick: function(el, e) {
            if (el.movement.bClick === false) {
                e.stopPropagation();
                e.preventDefault();
            }
        },
        loaded: function(el) {
            var hasSibling = (el.querySelector('[data-item="1"]').nextElementSibling) ? true : false,
                firstImg = el.querySelector('.owl-first-image'),
                lastItem = el.querySelector('.tnt-slider-item.last');
            this.scrubURLs(el);
            this.hideLoader(el, 1);
            if (hasSibling) {
                this.lazyLoad(el, 2);
                this.adController(el, 2);
                if (firstImg) {
                    firstImg.onload = function() {
                        utilities.autoHeight(el, 1);
                    };
                }
            }
            // lazyload last item in case you are sliding backwards
            if (lastItem) this.lazyLoad(el, lastItem.dataset.item);
            el.movement = {
                'bClick': true
            };
            el.classList.add('tnt-slider-loaded');
        },
        beforeSlide: function(el, pos) {
            this.lazyLoad(el, pos);
            this.adController(el, pos);
        },
        afterSlide: function(el, dir) {
            var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                activeSlide = el.querySelector('.active').dataset.item,
                event = new CustomEvent('tntCarouselSlid'),
                prevSlide = el.querySelector('.active').previousElementSibling || el.querySelector('.last'),
                nextSlide = el.querySelector('.active').nextElementSibling;
            
            el.dispatchEvent(event);
            
            this.slideCounter(el);

            if (prevSlide) this.lazyLoad(el, prevSlide.dataset.item);
            if (nextSlide) this.lazyLoad(el, nextSlide.dataset.item);

            if (el.querySelector('.active').nextElementSibling) this.lazyLoad(el, el.querySelector('.active').nextElementSibling.dataset.item);
            this.adController(el, null, dir);
            utilities.autoHeight(el, activeSlide);
            if (el.movement) el.movement.x = slideshowContainer.firstElementChild.offsetWidth * (activeSlide - 1);
        },
        currentPosition: function(el) {
            var activeSlide = el.querySelector('.active').dataset.item;
            return activeSlide;
        },
        scrubURLs: function(el) {
            if (location.href.indexOf('tncms/admin') != -1) {
                var links = el.getElementsByTagName('a');
                setTimeout(function() {
                    for (var i = 0; i < links.length; i++) {
                        var newLinks = (__tnt.scrubUrl(links[i].href));
                        links[i].href = newLinks;
                    }
                }, 500);
            }
        },
        visibleSlides: function(el) {
            // Auto columns
            if (el.dataset.columns == 'auto') {
                var stage = el.querySelector('.tnt-slider-outer');
                if (stage) {
                    var item = stage.querySelector('.tnt-slider-item');
                    if (item) {
                        var itemStyle = window.getComputedStyle(item),
                            itemWidth = item.offsetWidth + parseFloat(itemStyle.marginRight);
                        return Math.floor(stage.clientWidth / itemWidth);
                    }
                }
            }
            
            // Hard coded columns
            var w = window.innerWidth,
                columns;
            
            if (el.dataset.columns) {
                if (w < 768) {
                    columns = 1;
                } else if (w < 992) {
                    columns = 2;
                } else {
                    columns = el.dataset.columns;
                }
            } else {
                columns = 1;
            }
            return columns;
        },
        moveActiveClass: function(el) {
            if (el.dataset.columns) {
                var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                    curPos = parseInt(utilities.currentPosition(el)),
                    offset = parseInt(utilities.visibleSlides(el));
                
                for (var i = 0; i < slideshowContainer.children.length; i++) {
                    var sNum = slideshowContainer.children[i].dataset.item;
                    if (sNum >= curPos && sNum <= (curPos + (offset - 1))) {
                        slideshowContainer.children[i].classList.add('active');
                    } else {
                        slideshowContainer.children[i].classList.remove('active');
                    }
                }
            }
        },
        arrowElement: function(el, object) {
            var df = document.createDocumentFragment(),
                btn = document.createElement('div'),
                icon = document.createElement('i');
            
            btn.classList.add(object.btn);
            btn.classList.add('tnt-slider-btn');
            
            icon.classList.add('fas');
            icon.classList.add(object.icon);
            
            if (el.dataset.sliderType == 'pagination') {
                var textContainer = document.createElement('span');
                textContainer.classList.add('tnt-slider-control-txt');
                textContainer.innerHTML = object.text;
                btn.appendChild(textContainer);
            }
            
            btn.appendChild(icon);
            return df.appendChild(btn);
        },
        stageSize: function(el) {
            var visibleSlides = this.visibleSlides(el),
                slideshowContainer = el.querySelector('.tnt-slider-inner'),
                slideshowOuterStage = el.querySelector('.tnt-slider-outer'),
                slides = el.querySelectorAll('.tnt-slider-item'),
                slideWidthOffset = visibleSlides == 1 && window.innerWidth < 768 ? 80 : 0,
                slideWidth;
                
            if (el.dataset.columns) {
                slideWidth = ((slideshowOuterStage.offsetWidth - slideWidthOffset) - ((visibleSlides - 1) * 15)) / visibleSlides;
            } else {
                slideWidth = slideshowOuterStage.offsetWidth;
            }
            
            for (var i = 0; i < slides.length; i++) {
                if (el.dataset.columns) {
                    slides[i].style.marginRight = '15px';
                }
                slides[i].style.width = slideWidth + 'px';
            }
            if (el.dataset.columns) {
                var slideshowContainerPadding = 0;
                if (visibleSlides == 1 && window.innerWidth < 768) {
                    slideshowContainer.style.paddingLeft = '40px';
                    slideshowContainer.style.paddingRight = '40px';
                    slideshowContainerPadding = 80;
                } else {
                    slideshowContainer.style.paddingLeft = '';
                    slideshowContainer.style.paddingRight = '';
                }
                slideshowContainer.style.transition = 'transform 0.75s ease';
                slideshowContainer.style.width = (slides[0].getBoundingClientRect().width + 15) * slides.length + slideshowContainerPadding + 'px';
            } else {
                slideshowContainer.style.width = slideshowOuterStage.offsetWidth * slides.length + 'px';
                slideshowContainer.style.transition = 'transform 0.25s ease';
            }
        },
        setTranslate: function(el, pos) {
            var slider = el.querySelector('.tnt-slider'),
                slideshowContainer = el.querySelector('.tnt-slider-inner'),
                activeSlide = el.querySelector('[data-item="' + pos + '"]'),
                translatePos,
                marginRight = el.dataset.columns ? 15 : 0,
                id = el.id;
            
            if (pos == 1) {
                translatePos = 0;
            } else {
                translatePos = (pos - 1) * (activeSlide.getBoundingClientRect().width + marginRight);
            }
            
            slideshowContainer.style.transform = "translate3d(" + -translatePos + "px, 0, 0)";

            if (slider && slider.movement) {
                slider.movement.x = slideshowContainer.firstElementChild.offsetWidth * (pos - 1);
            }
        },
        lazyLoad: function(el, pos) {
            if (el.dataset.lazyLoad == 'true') {
                var activeSlide = el.querySelector('[data-item="' + pos + '"]'),
                    slide = activeSlide.querySelector('.owl-lazy');
                
                    if ((slide && !slide.src) || slide && slide.dataset.src != slide.src) {
                        slide.src = slide.dataset.src;
                        slide.onload = function() {
                            utilities.hideLoader(el, pos);
                        };
                    }
            }
        },
        autoHeight: function(el, pos) {
            if (el.dataset.autoHeight == 'true') {
                var activeSlide = el.querySelector('[data-item="' + pos + '"]'),
                    activeSlideHeight = activeSlide.offsetHeight,
                    autoHeightClass = el.querySelector('.tnt-slider-height');
                
                setTimeout(function() {
                    autoHeightClass.style.height = activeSlideHeight + 'px';
                }, 300);
            }
        },
        hideLoader: function(el, pos) {
            var activeSlide = el.querySelector('[data-item="' + pos + '"]'),
                loadingSlide = activeSlide.querySelector('.loading-slide');
            if (loadingSlide) loadingSlide.style.display = 'none';
        },
        slideCounter: function(el) {
            if (el.dataset.showCount != 'true') return;
            
            var activeSlide = el.querySelector('.active .item-container'),
                activeSlideCount = activeSlide.dataset.count,
                slideCount = el.querySelectorAll('.tnt-slider-counter');
            
            for (var i = 0; i < slideCount.length; ++i) {
                var currentCount = slideCount[i].querySelector('.index');
                if (activeSlideCount) {
                    currentCount.innerHTML = activeSlideCount;
                }
            }
        },
        adController: function(el, pos, dir) {
            /** Preload ad */
            if (dir) {
                var hasSibling, siblingPos;
                if (dir === 'next') {
                    hasSibling = (el.querySelector('.active').nextElementSibling) ? true : false;
                    if (hasSibling) siblingPos = el.querySelector('.active').nextElementSibling.dataset.item;
                } else if (dir === 'prev') {
                    hasSibling = (el.querySelector('.active').previousElementSibling) ? true : false;
                    if (hasSibling) siblingPos = el.querySelector('.active').previousElementSibling.dataset.item;
                }
                if (hasSibling != 'undefined' && siblingPos != 'undefined') {
                    this.adMovement(el, siblingPos, false);
                }
                /** Load ad */
            } else if (pos) {
                var refresh = false;
                if (el.querySelector('.active .photo-ad')) refresh = true;
                this.adMovement(el, pos, refresh);
            }
        },
        adMovement: function(el, pos, refresh) {
            var galleryAd = document.getElementById('tnt-gallery-ad');
            
            if (el.querySelector('[data-item="' + pos + '"] .photo-ad') &&
                !el.querySelector('[data-item="' + pos + '"] .photo-ad #tnt-gallery-ad') &&
                galleryAd) {
                
                // Remove current ad
                galleryAd.parentElement.removeChild(galleryAd);
                
                if (galleryAd.style.display === 'none') galleryAd.style.display = 'block';
                
                // Place ad in ad slide
                el.querySelector('[data-item="' + pos + '"] .gallery-ad').append(galleryAd);
                
                if (refresh) this.adRefresh(galleryAd.dataset.position, galleryAd.dataset.provider);
                
                // Refresh if ad is built and active
            } else if (el.querySelector('.tnt-slider-item.active[data-item="' + pos + '"] .photo-ad #tnt-gallery-ad')) {
                if (galleryAd.style.display === 'none') galleryAd.style.display = 'block';
                this.adRefresh(galleryAd.dataset.position, galleryAd.dataset.provider);
            }
        },
        adRefresh: function(position, provider) {
            try {
                if (provider.indexOf('dfp') !== -1 && typeof gptAdSlots != 'undefined') {
                    googletag.cmd.push(function() {
                        googletag.pubads().clear([gptAdSlots[position]]);
                        googletag.pubads().refresh([gptAdSlots[position]]);
                    });
                } else if (provider == 'blox' && typeof TNCMS.AdManager != 'undefined') {
                    TNCMS.AdManager.refresh({
                        region: position
                    });
                }
            } catch (e) {}
        },
        /** Autoslide */
        autoSlide: function(el) {
            if (el.dataset.autoSlide == 'true') {
                if (el.autoslide.allowAutoSlide === true) {
                    if (typeof el.autoslide.timer == 'undefined') el.autoslide.timer = false;
                    if (!el.autoslide.timer) {
                        el.autoslide.timer = setInterval(function() {
                            me.sliderApp.nextSlide(el);
                        }, 5000);
                    }
                } else {
                    clearInterval(el.autoslide.timer);
                    el.autoslide.timer = false;
                }
            }
        },
        moveStart: function(el, e) {
            var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                point = e.touches ? e.touches[0] : e,
                aElements = slideshowContainer.getElementsByTagName('a'),
                imgElements = slideshowContainer.getElementsByTagName('img'),
                totalSlides = parseInt(el.dataset.slideTotal),
                clones = utilities.countSliderClones(slideshowContainer),
                contentTotal = totalSlides - clones,
                visibleSlides = parseInt(utilities.visibleSlides(el)),
                i;

            // Return when not enough slides to slide
            if (contentTotal <= visibleSlides) {
                return;
            }

            // Cache marginRight so it isn't looked up each move event
            if (!el.properties) {
                el.properties = {'marginRight': parseFloat(window.getComputedStyle(slideshowContainer.querySelector('.tnt-slider-item')).marginRight)};
            }
            
            var defaultMovement = {
                bClick: false,
                directionLock: false,
                distanceX: 0,
                distanceY: 0,
                initiated: false,
                pointX: 0,
                pointY: 0,
                startX: 0,
                x: 0
            };
            el.movement = Object.assign({}, defaultMovement, el.movement);
            
            el.movement.initiated = true;
            el.movement.pointX = point.pageX;
            el.movement.pointY = point.pageY;
            el.movement.startX = point.pageX;
            
            for (i = 0; i < aElements.length; i++) {
                aElements[i].ondragstart = function(el, e) {
                    return false;
                };
            }
            
            for (i = 0; i < imgElements.length; i++) {
                imgElements[i].ondragstart = function(el, e) {
                    return false;
                };
            }
        },
        moveSlider: function(el, e) {
            if (el.movement && el.movement.initiated) {
                var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                    point = e.touches ? e.touches[0] : e,
                    deltaX = point.pageX - el.movement.pointX,
                    deltaY = point.pageY - el.movement.pointY,
                    absDistX = Math.abs(deltaX),
                    absDistY = Math.abs(deltaY),
                    newX;
                
                if (!el.movement.directionLock) {
                    el.movement.distanceX = el.movement.distanceX + absDistX;
                    el.movement.distanceY = el.movement.distanceY + absDistY;
                    
                    if (el.movement.distanceY > 16) {
                        el.movement.initiated = false;
                        el.movement.distanceY = 0;
                    } else if (el.movement.distanceX > 3) {
                        el.movement.directionLock = true;
                        el.movement.bClick = false;
                    }
                }
                
                if (el.movement.directionLock) {
                    var margins = (this.currentPosition(el) - 1) * el.properties.marginRight;
                    newX = el.movement.x + margins - deltaX;
                    slideshowContainer.style.transition = 'transform 0s ease';
                    slideshowContainer.style.transform = "translate3d(" + -newX + "px, 0, 0)";
                }
            }
        },
        moveEnd: function(el, e) {
            if (el.movement && el.movement.initiated) {
                
                var slideshowContainer = el.querySelector('.tnt-slider-inner'),
                    clientX = (e.changedTouches) ? e.changedTouches[0].clientX : e.clientX;
                
                if (el.dataset.columns) {
                    slideshowContainer.style.transition = 'transform 0.75s ease';
                } else {
                    slideshowContainer.style.transition = 'transform 0.25s ease';
                }
                
                if (clientX > 0 && clientX - el.movement.startX > 30) {
                    me.sliderApp.previousSlide(el);
                } else if (clientX > 0 && el.movement.startX - clientX > 30) {
                    me.sliderApp.nextSlide(el);
                } else {
                    utilities.setTranslate(el, this.currentPosition(el));
                }
                
                el.movement.directionLock = false;
                el.movement.initiated = false;
                el.movement.distanceX = 0;
                
                setTimeout(function() {
                    el.movement.bClick = true;
                }, 300);
            }
        },
        countSliderClones: function(collection) {
            return collection.querySelectorAll('.clone').length;
        },
        displayModal: function(el, e, target) {
            // Stop default behavior
            e.stopPropagation();
            e.preventDefault();
            
            var photoTarget = target.dataset.photoTarget,
                sliderId = el.dataset.id,
                updatedId = sliderId.replace('_photo_modal', '').replaceAll('_', '-'),
                modal = document.getElementById('photo-carousel-' + updatedId),
                items = modal.querySelectorAll('.modal .tnt-slider-item'),
                close = document.querySelector('.modal .close');
            
            // Kill current active
            try {
                document.querySelector('#tnt-slider-' + sliderId + ' .active').classList.remove('active');
            } catch (e) {
                console.error(e);
            }
            
            // Set target item to active
            modal.querySelector(photoTarget).closest('.tnt-slider-item').classList.add('active');
            
            var activeSlide = document.querySelector('#tnt-slider-' + sliderId + ' .active').dataset.item,
                modalActive = modal.querySelector('.modal .tnt-slider-item.active');
            
            // Trigger image load
            this.lazyLoad(el, modalActive.dataset.item);
            
            // Display modal
            modal.classList.add('in');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
            
            // Refresh hidden slider in modal and add active class to correct slider item
            me.sliderApp.resize(modal, activeSlide);
            
            // Log modal open
            if (el.dataset.assetType) {
                __tnt.trackEvent({
                    'category': 'tnt-lightbox',
                    'action': 'carousel-open',
                    'label': el.dataset.assetType,
                    'value': '1'
                });
            }
            
            setTimeout(function() {
                modalActive.classList.remove('active');
                for (var i = 0; i < items.length; i++) {
                    if (items[i].dataset.item == activeSlide) {
                        items[i].classList.add('active');
                    }
                }
            }, 250);
        },
        modalClose: function(el) {
            // Initialize close behavior
            var sliderId = el.dataset.id,
                updatedId = updatedId = sliderId.replace('_photo_modal', '').replaceAll('_', '-'),
                modal = document.getElementById('photo-carousel-' + updatedId),
                close = document.querySelector('#photo-carousel-' + updatedId + '.modal .close');
            
            if (modal) {
                var modalActive = modal.querySelector('.tnt-slider-item.active');
                close.addEventListener('click', function() {
                    var companionID = el.dataset.companion;
                    if (companionID) var companion = document.getElementById(companionID);
                    modal.classList.remove('in');
                    modal.style.display = '';
                    document.body.classList.remove('modal-open');
                    modalActive.classList.remove('active');
                    
                    if (companion) me.sliderApp.resize(companion, modalActive.dataset.item);
                });
            }
        }
    };
    
    // initiate slider
    if (document.readyState == 'loading') {
        document.addEventListener('DOMContentLoaded', function() {
            me.sliderApp.init();
        });
    } else {
        me.sliderApp.init();
    }
})(document, __tnt);